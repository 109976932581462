<template>
  <div class="details min_main">
    <div class="page_width">
      <div class="details_container">
        <div class="details_thumb flex">
          <div class="thumb_left">
            <div class="product_title">{{product.title}}</div>
            <div class="product_img" v-if="product.brand_image">
              <img :src="product.brand_image" alt="">
            </div>
            <div class="product_info">
              <div class="info_title">Product info</div>
              <div class="info_list grid ">
                <div class="list_thumb flex grid_md_6 grid_sm_12 grid_xs_12">
                  <div class="list_title">
                    <span>Delivery speed</span>
                  </div>
                  <div class="list_mag">{{ product.delivery_time}} </div>
                </div>
                <div class="list_thumb flex grid_md_6 grid_sm_12 grid_xs_12">
                  <div class="list_title">
                    <span>Delivery method</span>
                  </div>
                  <div class="list_mag">{{product.delivery_method}} </div>
                </div>
              </div>
              <hr class="md_line">
              <!-- <div class="info_list grid ">
                <div class="list_thumb flex grid_md_6 grid_sm_12 grid_xs_12">
                  <div class="list_title">
                    <span>7 Days to Die PC - Steam (SEA)</span>
                  </div>
                  <div class="list_mag">7 Days to Die - Steam Key (SEA)</div>
                </div>  
                
              </div> -->
              <div class="details_info" v-if='product.details'>
                <div class="info_title">Details</div>
                <div v-for="(i,index) in product.details" :key="index" class="details_tab">{{ i }}</div>
              </div>
             
            </div>
          </div>
          <div class="thumb_right">
            <div class="price_group">
              <div class="count_ava">{{ product.available_qty }} available</div>
              <div class="flex_content">
                <div class="count_content flex_content">
                  <div class="count_btn pointer" @click="updateCount('cut')"><i class="icon-minus iconfont flex_content"></i></div>
                  <div class="count_inp">
                    <el-input v-model="count"></el-input>
                  </div>
                  <div class="count_btn add pointer" @click="updateCount('add')"><i class="icon-plus iconfont flex_content"></i></div>
                </div>
              </div>
              
              <hr class="md_line">
              <div class="price_total flex_sb">
                <div class="price_label">Total Price</div>
                <div class="price_amount">{{ product.price }}<span>USD</span> </div>
              </div>
              <div class="buy_btn default_btn pointer hidden-xs-only"  @click="toPage('checkout')">Buy now</div>
            </div>
            <div class="price_group seller_content ">
              <div class="seller_msg flex_sb">
                <div class="flex_content">
                  <div class="msg_good flex_content">
                    <div class="iconfont icon-dianzan"></div>
                    <div class="msg_per">0.00%</div>   
                  </div>
                  <div class="colum_line"></div>
                  <div class="msg_sold">0 sold</div> 
                </div>
                <div class="other_seller">Other sellers({{ sellers.length }})</div>
              </div>
              <div class="md_line"></div>
              <div class="seller_group" style="margin-top:24px;">
                <div class="seller_thumb flex">
                  <div class="seller_avatar">
                    <img :src="product.user_avatar" alt="" v-if='product.user_avatar'>
                    <div class='user_avatar flex_content' v-else>G</div>
                  </div>
                  <div class="seller_info">
                    <div class="seller_name">{{ product.username }}</div>
                    <div class="seller_level"> level {{ product.user_level }}</div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!-- other sellers -->
        <div class="seller_container">
          <div class="seller_title">Other sellers ({{ sellers.length }})</div>
          <div class="other_seller_thumb hidden-md-and-down">
            <div class="seller_content flex" v-for="(item,index) in sellers" :key="index" @click="toDetails(item.offer_id)">
              <div class="seller_thumb flex width_per_2" >
                  <div class="seller_avatar">
                    <img :src="item.user_avatar" alt="" v-if='item.user_avatar'>
                    <div class='user_avatar flex_content' v-else>G</div>
                  </div>
                  <div class="seller_info">
                    <div class="seller_name">{{ item.username }}</div>
                    <div class="seller_level"> level {{ item.user_level }}</div>
                  </div>
                </div>
                <div class="flex_content_start width_per_2">
                  <div class="msg_good flex_content">
                    <div class="iconfont icon-dianzan"></div>
                    <div class="msg_per">0.00%</div>   
                  </div>
                 
                  <div class="msg_sold" style="margin-left:4px;">0 sold</div> 
                </div>
                <div class="popover_list flex_content_start">
                  <div class="list_tab " >
                    <el-popover
                      placement="top-start"
                      title=""
                      popper-class="tab_popper"
                      width="100px"
                      :visible-arrow="false"
                      trigger="hover"
                      :open-delay=800
                      :content="'Minimum purchase:'+ item.min_qty">
                     <div class="tab_content pointer" slot="reference">Min.{{ item.min_qty }}</div>
                    </el-popover>
                  </div>
                  <div class="list_tab " >
                    <el-popover
                      placement="top-start"
                      title=""
                      popper-class="tab_popper"
                      width="100px"
                      :visible-arrow="false"
                      trigger="hover"
                      :open-delay=800
                      :content="'Available stock:'+ item.available_qty">
                     <div class="tab_content pointer" slot="reference">{{ item.available_qty }}</div>
                    </el-popover>
                  </div>
                  <div class="list_tab " >
                    <el-popover
                      placement="top-start"
                      title=""
                      popper-class="tab_popper"
                      width="100px"
                      :visible-arrow="false"
                      trigger="hover"
                      :open-delay=800
                      :content="'Delivery speed:'+ item.delivery_time">
                     <div class="tab_content pointer" slot="reference">{{ item.delivery_time }}</div>
                    </el-popover>
                  </div>
                </div>
                <div class="price_group flex_content_start width_per_2">{{ item.price }} <span>USD</span></div>
            </div>
          </div>
          <div class="other_seller_thumb_mobile hidden-lg-and-up">
            <div class="seller_content" v-for="(item,index) in sellers" :key="index" @click="toDetails(item.offer_id)">
              <div class="flex_sb">
                <div class="seller_thumb flex" >
                  <div class="seller_avatar">
                    <img :src="item.user_avatar" alt="" v-if='item.user_avatar'>
                    <div class='user_avatar flex_content' v-else>G</div>
                  </div>
                  <div class="seller_info">
                    <div class="seller_name">{{ item.username }}</div>
                    <div class="seller_level"> level {{ item.user_level }}</div>
                  </div>
               </div>
               <div class="price_group flex_content_start width_per_2">{{ item.price }} <span>USD</span></div>
              </div>
              

                <div class="flex_content_start " style="margin:16px 0;">
                  <div class="msg_good flex_content">
                    <div class="iconfont icon-dianzan"></div>
                    <div class="msg_per">0.00%</div>   
                  </div>   
                  <div class="msg_sold" style="margin-left:4px;">0 sold</div> 
                </div>
                <div class="popover_list flex_content_start">
                  <div class="list_tab " >
                    <el-popover
                      placement="top-start"
                      title=""
                      popper-class="tab_popper"
                      width="100px"
                      :visible-arrow="false"
                      trigger="hover"
                      :open-delay=800
                      :content="'Minimum purchase:'+ item.min_qty">
                     <div class="tab_content pointer" slot="reference">Min.{{ item.min_qty }}</div>
                    </el-popover>
                  </div>
                  <div class="list_tab " >
                    <el-popover
                      placement="top-start"
                      title=""
                      popper-class="tab_popper"
                      width="100px"
                      :visible-arrow="false"
                      trigger="hover"
                      :open-delay=800
                      :content="'Available stock:'+ item.available_qty">
                     <div class="tab_content pointer" slot="reference">{{ item.available_qty }}</div>
                    </el-popover>
                  </div>
                  <div class="list_tab " >
                    <el-popover
                      placement="top-start"
                      title=""
                      popper-class="tab_popper"
                      width="100px"
                      :visible-arrow="false"
                      trigger="hover"
                      :open-delay=800
                      :content="'Delivery speed:'+ item.delivery_time">
                     <div class="tab_content pointer" slot="reference">{{ item.delivery_time }}</div>
                    </el-popover>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile buy button fixed -->
    <div class='mobile_button hidden-sm-and-up'>
        <div class="button_group page_width flex">
          
          <div class="default_button flex_content pointer red_button" @click="toPage('checkout')">Buy Now</div>
        </div>
   </div>
  </div>
</template>

<script>
import { setS_storage,randomLeString } from '../../utils/filters';



export default {
  inject: ['reload'],
  data () {
    return {
      count:1,
      offer_id:'',
      product:'',
      filter_attr:'',
      brand_id:"",
      service_id:'',
      region_id:'',
      sellers:[]
    }
  },

  created () {
    this.offer_id = this.$route.query.offer_id
    this.getData()
  },
  methods:{
    getData(){
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        let offer_id = this.offer_id
        this.$axios({
            url:'https://sls.g2g.com/offer/'+offer_id+'?currency=USD&country=CN&include_out_of_stock=1',
            method:'get',
            data:{},
            headers: {
                'Content-type':'application/json;charset=utf-8 '
            }
        }).then(res=>{
            let result = res.data.payload
            result.price = result.display_price
            let description = result.description 
            let details =''
            let brand_image = ''
            if(result.gallery_images){
              brand_image = result.gallery_images[0]
            }
            result.brand_image = brand_image
            if(description){
               details = description.split('\n')
            }
            
            result.details = details
            let delivery_speed = result.delivery_speed
            let delivery_time = ''
            if(delivery_speed){
              delivery_time = delivery_speed
              if(result.delivery_speed_details.length>0){
                delivery_time =result.delivery_speed_details[0].delivery_time + ' mins'
              }
            }
            result.delivery_time = delivery_time
            let delivery_method = ''
            if(result.delivery_method_ids.length>0){
              let ids = result.delivery_method_ids[0]
              if(ids == 'instant_inventory'){
                delivery_method = 'Auto delivery'
              }else{
                let method_details =  result.delivery_method_details
                method_details.map(v=>{
                  if(v.delivery_method_id == ids){
                    delivery_method = v.label.en
                  }
                })
              }
              
             
            }
            result.delivery_method = delivery_method  
            this.brand_id = result.brand_id
            this.filter_attr = result.offer_attributes[0].collection_id + ':'+result.offer_attributes[0].dataset_id
            this.region_id = result.region_id
            this.product = result
            loading.close()
            this.getSellers()
            // console.log('result',result)
           
        }).catch(()=>{
            loading.close()
        })
    },
    getSellers(){
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        let service_id = this.service_id
        let brand_id = this.brand_id
        let filter_attr = this.filter_attr
        let region_id = this.region_id
        this.$axios({
            url:'https://sls.g2g.com/offer/search?service_id='+service_id+'&brand_id='+brand_id+'&region_id='+region_id+'&filter_attr='+filter_attr+'&page_size=20&group=0&currency=USD&country=CN',
            method:'get',
            data:{},
            headers: {
                'Content-type':'application/json;charset=utf-8 '
            }
        }).then(res=>{
            let result = res.data.payload.results.map(v=>{
            let delivery_speed = v.delivery_speed
            let delivery_time = ''
            if(delivery_speed){
              delivery_time = delivery_speed
              if(v.delivery_speed_details.length>0){
                delivery_time =v.delivery_speed_details[0].delivery_time + ' mins'
              }
            }
              v.delivery_time = delivery_time
              v.price = v.display_price
              return v
            })
            this.sellers = result
            console.log('result',result)
            loading.close()
        }).catch(()=>{
            loading.close()
        })
    },
    toDetails(id){
      this.$router.push('/details?offer_id='+id)
      this.reload()
    },
    toPage(path){
      let item = this.product
      let orderId = Date.now() + randomLeString(4)
      item.count = this.count
      setS_storage('checkOutList',item)
      this.$router.push('/'+path +'?orderId='+orderId)
    },
    updateCount(type){
      let count = this.count
      if(type=='cut'){
        if(count == 1){return}
        count = count-1
      }else{
        count = count+1
      }
      this.count = count
    }

  }
}
</script>
<style lang='scss' scoped>
.details {
  .details_container{
    padding:24px 0;
    .thumb_left{
      margin-right:24px;
      flex:1;
      .product_title{
        color:#303030;
        font-size:30px;
        font-weight: 600;
        text-align:left;
      }
      .product_img{
        width: 100%;
        margin:48px 0 24px;
        height:300px;
        >img{
          object-fit: contain;
        }
      }
      .product_info{
        padding:24px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        .info_title{
          font-size:20px;
          font-weight: 600;
          color:#303030;
          // margin-bottom:24px;
          text-align: left;
        }
        .info_list{
          text-align:left;
          .list_thumb{
            padding-top:24px;
            .list_title{
              min-width:150px;
              font-size:13px;
              color:#777;
              position: relative;
              >span{
                padding-right:8px;
                background:#f7f7f9;
                z-index: 2;
                position: relative;
              }
              
              &::after{
                content: "";
                position: absolute;
                right: 8px;
                left:30px;
                top: 10px;
                border-top: 1px dotted #999;
                z-index: 1;
              }
            }
            .list_mag{
              font-size:13px;
              color:#303030;
            }
          }
        }
        .details_info{
          text-align: left;
          margin-top:24px;
          .info_title{
            margin-bottom:24px;
          }
          .details_tab{
            font-size: 14px;
          }
        }
      }
    }
    .thumb_right{
      width: 380px;   
      margin-top:100px;
      .price_group{
        padding:16px 30px;
        border-radius: 8px;
        border:1px solid rgba(0, 0, 0, 0.12);
        .count_ava{
          font-size:14px;
          color:#777;
          margin:16px 0;
        }
        .count_content{
          max-width: 220px;
          height: 48px;
          box-shadow: 0 0.14px 2.29266px rgba(0, 0, 0, 0.03), 0 0.37px 4.42626px rgba(0, 0, 0, 0.05), 0 3px 7px rgba(0, 0, 0, 0.09);
          border-radius: 24px;
          background:#fff;
          .count_btn{
            width: 38px;
            height:38px;
            background:#eee;
            margin:0 5px;
            font-size:20px;
            color:#0000008a;
            border-radius: 50%;
            >i{
              height: 100%;
            }
          }
          .count_inp{
            flex:1;
            /deep/.el-input__inner{
              border:none;
              text-align: center
            }
            input{ 
              &:focus{
                  // outline:none!important;
                  border:none!important;
              }
            }
          }
          .add{
            background:#f03827;
            color:#fff;
          }
        }
        .price_total{
          margin-top:24px;
          color:#000;
          font-size:20px;
          font-weight:600;
          span{
            font-size:10px;
            font-weight: 400;
            
          }
         
        }
        .buy_btn{
            min-height:48px;
            color:#fff;
            font-size:20px;
            background:#f03827;
            border-radius: 4px;
            margin:16px 0;
            width:100%;
            line-height: 48px;
            &:hover{
              opacity: 0.6;
            }
          }
      }
      .seller_content{
        margin-top:24px;
        
      }
    }
  }
  .msg_good{
    font-size:13px;
    color:#00c853;
    .msg_per{
      margin-left:4px;
    }
  }
  .msg_sold{
    font-size:13px;
    color:#777;
  }
  .price_group{
    .seller_msg{
      .colum_line{
        margin:0 8px;
        width:1px;
        height:20px;
        background-color: rgba(0, 0, 0, 0.12);
      }
     
    }
    .other_seller{
      color:#303030;
      font-size:13px;
    }
  }
  .seller_thumb{
      .seller_avatar{
        width:40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
        margin-right:8px;
        .user_avatar{
          font-weight:500;
          background:#c62828;
          font-size:12px;
          height:100%;
          color:#fff;
        }
      }
      .seller_info{
        margin-left:8px;
        text-align: left;
        .seller_name{
          font-size:12px;
          color:#303030;
        }
        .seller_level{
          color:#777;
          font-size:10px;
        }
      }
  }
  .seller_container{
    margin-top:48px;
    
    .seller_title{
      text-align: left;
      font-size:20px;
      margin-bottom:16px;
    }
    .width_per_2{
      width:15%;
    }
    .other_seller_thumb{
      border:1px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      
    }
    .seller_content{
        padding:16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        top: 0;
        transition: all 0.2s ease-out;
        position: relative;
        background-color: transparent;
        &:last-child{
          border-bottom:none;
        }
        &:hover{
          top:-3px;
          z-index:2;
          box-shadow: 0 3px rgba(0, 0, 0, .2);
        
        }
       .popover_list{
        flex:1;
        .list_tab{
          padding-right:8px;
          .tab_content{
            padding:2px 6px;
            background:#e9e9ee;
            border-radius: 4px;
            font-size:12px;
            color:#000;
          }
        }
       } 
        .price_group{
          font-size:14px;
          text-align: right;
          font-weight: 600;
          justify-content: end;
          >span{
            font-size:12px;
            margin-left:8px;
            font-weight: 400;
          }
        }
      }
  }
  .mobile_button{
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    z-index:999;
    background-color: #fff;
    box-shadow: 0 -0.14px 2.29266px rgba(0, 0, 0, 0.03), 0 -0.37px 4.42626px rgba(0, 0, 0, 0.05), 0 -3px 7px rgba(0, 0, 0, 0.09);
    .button_group{
      padding-top:16px;
      padding-bottom:16px;
      .red_button{
        width:100%;
        border-radius: 4px;
      }
    }
  }
}

</style>